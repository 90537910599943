<template>
  <div class="tabs">
    <!--closable这里说明home是不能关闭的-->
    <el-tag
      :key="tag.name"
      size="small"
      v-for="(tag, index) in homeTagsList"
      :closable="tag.name !== 'index'"
      :disable-transitions="false"
      @close="handleClose(tag, index)"
      @click="changeMenu(tag)"
      :effect="$route.name === tag.name ? 'dark' : 'plain'"
    >
      {{ tag.meta.title }}
    </el-tag>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters(["homeTagsList"]),
  },
  mounted() {
    console.log(this.homeTagsList);
  },
  methods: {
    ...mapMutations({
      close: "closeTab",
    }),
    //关闭标签
    handleClose(tag, index) {
      let homeTagsList = this.$store.getters.homeTagsList;
      homeTagsList = homeTagsList.filter((item) => item.name != tag.name);
      this.$store.dispatch("delHomeTagsList", homeTagsList);
      if (tag.path == this.$route.path) {
        this.$router.push(homeTagsList[index - 1].path);
      }
    },
    //选择标签跳转路由
    changeMenu(item) {
      this.$store.dispatch("setActivePath", item.path);
      this.$router.push({ name: item.name });
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.el-tag {
  margin-left: 10px;
}
</style>
