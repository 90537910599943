<template>
  <div class="main">
    <div class="nav_bar">
      <div class="left">
        <el-button @click="toggleCollapse()">
          <i class="el-icon-s-fold" />
        </el-button>
        <!-- 面包屑导航区域 -->
        <el-breadcrumb v-if="currentRoute.path !== '/index'" l-breadcrumbseparator-class="el-icon-arrow-right">
          <template v-for="(item, index) in currentRoute.matched">
            <el-breadcrumb-item v-if="index == 0" :key="item.path" :to="{ path: '/' }" @click="activePathClick()">
              {{ item.meta.title }}
            </el-breadcrumb-item>
            <el-breadcrumb-item v-if="index !== 0" :key="item.path">
              {{ item.meta.title }}
            </el-breadcrumb-item>
          </template>
        </el-breadcrumb>
        <el-breadcrumb v-if="currentRoute.path == '/index'" l-breadcrumbseparator-class="el-icon-arrow-right">
          <el-breadcrumb-item key="/index">首页</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="right">
        <el-button @click="screen()">
          <i class="el-icon-full-screen" />
        </el-button>
        <el-dropdown trigger="click">
          <el-button>用户信息</el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>个人中心</el-dropdown-item>
              <el-dropdown-item @click.native="logoutFn">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <!-- <div class="tags_view">
      <TagsView />
    </div> -->
    <div class="scrollbar-main" style="min-width: 1000px">
      <el-scrollbar wrap-class="scrollbar-main" style="height: 100%">
        <router-view />
      </el-scrollbar>
    </div>
  </div>
</template>
<script>
import TagsView from '../TagsView/index.vue'
import { mapGetters } from 'vuex'
import { FullScreen } from '@/utils/index.js'
import Cookies from 'js-cookie'
export default {
  name: 'Index',
  components: {
    TagsView,
  },
  props: {
    currentRoute: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      isFullscreen: false,
    }
  },
  computed: {
    ...mapGetters(['isCollapse']),
  },
  mounted() {},
  methods: {
    screen() {
      this.isFullscreen = FullScreen(this.isFullscreen)
    },
    toggleCollapse() {
      this.$store.dispatch('setCollapse', !this.isCollapse)
    },
    logoutFn() {
      window.localStorage.setItem('Token', '')
      this.$router.push({ name: 'login' }) // 返回登录页面
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  min-width: 900px;
  color: #000;
}
.nav_bar {
  width: 100%;
  height: 50px;
  @include flexBetween;
  background: #f7f5f5;
  .left {
    display: flex;
    align-items: center;
  }
  .right {
    display: flex;
    div {
      margin: 0 8px;
    }
  }

  .el-button {
    height: 50px;
    border: none;
    background: #f7f5f5;
    color: #000 !important;
  }
}
.tags_view {
  width: 100%;
  height: 50px;
  background: #fff;
  box-shadow: 0 0px 0.5px 0;
}
.scrollbar-main {
  height: calc(100vh - 50px);
}
</style>
