<template>
  <el-container>
    <el-aside :width="isCollapse ? '70px' : '240px'">
      <asideBar />
    </el-aside>
    <el-main>
      <mainBar :current-route="$route" />
    </el-main>
  </el-container>
</template>

<script>
import asideBar from "./components/aside_bar/Index.vue";
import mainBar from "./components/main_bar/Index";
import { mapGetters } from "vuex";
export default {
  name: "Index",
  components: {
    asideBar,
    mainBar,
  },
  computed: {
    ...mapGetters(["isCollapse"]),
  },
  created() {
    this.menuList();
  },
  mounted() {
    window.onresize = () => {
      const tableHeight = window.innerHeight - 300;
      console.log(tableHeight);
      this.$store.dispatch("setInnerHeight", tableHeight);
    };
  },
  methods: {
    async menuList() {
      const res = await this.$API.menu.get();
      if (res.status !== 200) return "";
      this.$store.dispatch("menuList", res.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-container {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  .el-main {
    background: #fff;
    height: 100%;
  }
  .el-aside {
    background: #f7f5f5;
    height: 100%;
  }
}
</style>
