<template>
  <router-link class="sidebar-logo-link" to="/">
    <img class="sidebar-logo" src="@/assets/images/home/logo.png" />
    <div class="sidebar-title" v-if="!isCollapse"></div>
  </router-link>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SidebarLogo",
  computed: {
    ...mapGetters(["isCollapse"]),
  },
};
</script>

<style lang="scss" scoped>
.sidebar-logo-link {
  width: 100%;
  & .sidebar-logo {
    width: 40px;
    display: inline-block;
    height: 40px;
    margin-right: 12px;
  }
  & .sidebar-title {
    display: inline-block;
    margin: 0;
    color: #435ebe;
    font-weight: 600;
    font-size: 15px;
  }
}
</style>
